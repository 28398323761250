import { Component } from '@angular/core';
import { OnInit    } from '@angular/core';

@Component({
    selector    : 'logo-kodear',
    templateUrl : './logo-kodear.component.html',
    styleUrls   : ['./logo-kodear.component.scss']
})
export class LogoKodearComponent implements OnInit {

    constructor() { }

    public ngOnInit(): void {
    }

}
