<app-layout-general>
    <contenido class="main" fxLayout.gt-md="row" fxLayoutAlign="center center">
        <mat-card class="card" fxLayout="column" fxLayoutAlign="start center">
            <h2>Restablecer contraseña</h2>


            <form [formGroup]="form" class="form" fxLayout="row wrap" (ngSubmit)="submit()" [autocomplete]="false">
                <mat-form-field fxFlex="100" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input formControlName="email" matInput>
                    <mat-error *ngIf="error('email')">{{error('email')}}</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="100" appearance="outline">
                    <mat-label>Contraseña</mat-label>
                    <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
                        <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <input formControlName="password" matInput [type]="hidePassword ? 'password' : 'text'" required>
                    <mat-error *ngIf="error('password')">{{error('password')}}</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="100" appearance="outline">
                    <mat-label>Confirmar Contraseña</mat-label>
                    <input formControlName="password_confirmation" matInput type="password" required>
                    <mat-error *ngIf="error('password_confirmation')">{{error('password_confirmation')}}</mat-error>
                </mat-form-field>

                <button fxFlex="100" class="submit-button borderRadius" color="primary" mat-raised-button  type="submit">Enviar</button>
            </form>
        </mat-card>
    </contenido>
</app-layout-general>
