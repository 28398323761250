<app-layout-general>
    <contenido class="main" fxLayout.gt-md="row" fxLayoutAlign="center center">
        <mat-card class="card" fxLayout="column" fxLayoutAlign="start center">
            <h2>¿Olvidaste tu contraseña?</h2>
            <div class="mat-h4 description">
                Ingreśa tu email y te enviaremos un link para restablecerla
            </div>

            <form [formGroup]="form" class="form" fxLayout="row wrap" (ngSubmit)="submit()" [autocomplete]="false">
                <mat-form-field fxFlex="100" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input formControlName="email" matInput required (keyup)="onKey($event)">
                    <mat-error *ngIf="error('email')">{{error('email')}}</mat-error>
                </mat-form-field>

                <p *ngIf="showErrorMessage" style="color: red;">El email ingresado no está registrado</p>
                
                <p *ngIf="showOnboardingLink" fxFlex="100" style="color: red; font-size: 15px;">Para recuperar su contraseña ingrese 
                <a   class="onboarding-link" [href]="getOnboardingLink()" target="_blank">
                    aquí
                </a>

                </p>

                <p *ngIf="showSuccessMessage" style="color: green;">Hemos enviado un email con el link de restauración</p>


                <button *ngIf="!showOnboardingLink" fxFlex="100" class="submit-button borderRadius" color="primary" mat-raised-button  type="submit">Enviar</button>
                
                <button *ngIf="showOnboardingLink" fxFlex="100" class="submit-button disabled-button borderRadius" mat-raised-button color="primary" disabled>Enviar</button>

                <a fxFlex="100" routerLink="/auth/login" class="salir">Salir</a>

            </form>
        </mat-card>
    </contenido>
</app-layout-general>