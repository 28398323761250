import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer-desktop',
    templateUrl: './footer-desktop.component.html',
    styleUrls: ['./footer-desktop.component.scss']
})
export class FooterDesktopComponent implements OnInit {

    ngOnInit(): void {
    }

}
